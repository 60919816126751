import styled from 'styled-components';

export const StyledBoldTitle = styled.h1`
  color: ${({ white, theme }) => (white ? theme.colors.white : theme.colors.lighterBlack)};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  margin: 0;

  @media only screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }

  @media only screen and (max-width: 500px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;
