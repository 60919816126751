import styled, { css } from "styled-components";
import { Link } from "gatsby";

export const StyledHeaderWrapper = styled.header`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: ${({ active, theme }) =>
    active ? theme.colors.white : "transparent"};
  transition: background 0.3s;
  height: 86px;
  transition: height 0.2s linear;
  @media only screen and (max-width: 950px) {
    height: 60px;
  }
`;
export const StyledHeaderContent = styled.div`
  display: flex;
`;

export const StyledHeaderLogo = styled(Link)`
  height: 86px;
  padding-top: 16px;
  width: fit-content;
  @media only screen and (max-width: 950px) {
    height: 60px;
    padding-top: 13.5px;
  }
`;

export const StyledHeaderImage = styled.img`
  width: 162px;
  height: auto;
  margin-right: 50px;
  @media only screen and (max-width: 950px) {
    width: 100px;
    margin-right: 0;
  }
`;

export const StyledHeaderNav = styled.nav`
  width: 100%;
  display: inline-block;
  margin-top: 31px;
  text-align: right;
  @media only screen and (max-width: 950px) {
    position: fixed;
    height: 0;
    top: 0;
    left: 0;
    width: 100vw;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: height 0.2s linear;
    background: ${({ theme }) => theme.colors.yellow};
    height: ${({ isActive }) => (isActive ? "100vh" : "0")};
    display: flex;
    flex-direction: column;
  }
`;

export const StyledHeaderNavLink = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-size: ${({ theme }) => theme.fontSizes.small};
  text-decoration: none;
  margin-right: 60px;
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 0;
    height: 4px;
    background: ${({ theme }) => theme.colors.yellow};
    transition: width 0.3s;
  }

  &.active {
    &::after {
      width: 100%;
    }
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }

  @media only screen and (max-width: 950px) {
    margin-right: 0px;
    padding-top: 15px;
  }
`;

export const StyledHeaderButton = styled.button`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.orange};
  font-size: ${({ theme }) => theme.fontSizes.small};
  border: none;
  margin-left: auto;
  outline: none;
  padding: 10px 22px;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: bold;

  &:hover {
    background: ${({ theme }) => theme.colors.darkerOrange};
    transform: scale(1.03);
  }

  @media only screen and (max-width: 1660px) {
    margin-right: 15px;
  }
  @media only screen and (max-width: 950px) {
    margin: 15px 0px;
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.white};
  }
`;

const hamburgerActiveStyle = css`
  .bar1 {
    transform: rotate(-45deg) translate(-6px, 6px);
  }

  .bar2 {
    opacity: 0;
  }

  .bar3 {
    transform: rotate(45deg) translate(-5px, -8px);
  }
`;

export const StyledHamburger = styled.div`
  height: fit-content;
  display: none;
  cursor: pointer;
  float: right;
  z-index: 200;
  margin-left: auto;
  margin-right: 15px;
  padding-top: 15px;

  @media only screen and (max-width: 950px) {
    display: inline-block;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
  }

  ${({ isActive }) => (isActive ? hamburgerActiveStyle : null)}
`;
