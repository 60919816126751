import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import {
  StyledHamburger,
  StyledHeaderButton,
  StyledHeaderContent,
  StyledHeaderImage,
  StyledHeaderLogo,
  StyledHeaderNav,
  StyledHeaderNavLink,
  StyledHeaderWrapper,
} from './header.style';
import { StyledCenter } from '../../utils/styledComponents/center.style';
import Logo from '../../images/logo.png';
import { LANG } from '../../lang/lang';

const Header = (): JSX.Element => {
  const [activeNav, setActiveNav] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const handleOpenMenu = () => {
    setIsMobileMenuVisible((isMobileMenuVisible) => !isMobileMenuVisible);
    setActiveNav(!activeNav ? !isMobileMenuVisible : activeNav);
  };

  const menuItems = Object.entries(LANG.PL.links.topBar).map((e) => e[1]);
  const renderMenuLinks = () => {
    return menuItems.map((el) => (
      <StyledHeaderNavLink key={el.id} to={el.url} activeClassName="active">
        {el.title}
      </StyledHeaderNavLink>
    ));
  };

  const changeNavBackground = () => {
    window.pageYOffset >= 86 ? setActiveNav(true) : setActiveNav(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNavBackground);

    return () => {
      window.removeEventListener('scroll', changeNavBackground);
    };
  }, []);

  return (
    <StyledHeaderWrapper isActive={isMobileMenuVisible} active={activeNav}>
      <StyledCenter>
        <StyledHeaderContent>
          <StyledHeaderLogo to="/">
            <StyledHeaderImage src={Logo} alt={LANG.PL.logo} />
          </StyledHeaderLogo>
          <StyledHeaderNav isActive={isMobileMenuVisible}>
            {renderMenuLinks()}
            <StyledHeaderButton
              as={Link}
              to={LANG.PL.links.contactUsButton.url}
            >
              {LANG.PL.links.contactUsButton.title}
            </StyledHeaderButton>
          </StyledHeaderNav>
          <StyledHamburger
            isActive={isMobileMenuVisible}
            onClick={handleOpenMenu}
          >
            <div className="bar1" />
            <div className="bar2" />
            <div className="bar3" />
          </StyledHamburger>
        </StyledHeaderContent>
      </StyledCenter>
    </StyledHeaderWrapper>
  );
};

export default Header;
