export const LANG = {
  PL: {
    logo: 'Logo Voltspot - instalacje fotowoltaiczne',
    notFound: '404 - podstrona nie istnieje',
    seoTitle: 'Fotowoltaika',
    links: {
      topBar: {
        ourCompany: {
          id: 1,
          title: 'Nasza Firma',
          url: '/nasza-firma',
        },
        realizations: {
          id: 2,
          title: 'Realizacje',
          url: '/realizacje',
        },
        blog: {
          id: 4,
          title: 'Blog',
          url: '/blog',
        },
      },
      contactUsButton: {
        title: 'Skontaktuj się',
        url: '/kontakt',
      },
    },
    heroSection: {
      title: 'Profesjonalne instalacje',
      subTitle: 'FOTOWOLTAICZNE',
      button: {
        title: 'Zamów konsultację',
        url: '/kontakt',
      },
    },
    aboutSection: {
      title: 'Czym jest',
      subTitle: 'Voltspot',
      desc_1:
        'Firma Voltspot specjalizuje się w instalacjach fotowoltaicznych dla najbardziej wymagających odbiorców. Mamy ogromne doświadczenie w dostarczaniu zaawansowanych technologicznie elektrowni słonecznych dla firm. Dzięki temu potrafimy zaprojektować nawet najbardziej nietypową instalację, również na budynku jednorodzinnym. Ogromnym atutem firmy Voltspot są ekipy montażowe. W zespole montażowym zawsze znajduje się dekarz oraz elektryk, a w odbiorze instalacji zawsze bierze udział inżynier który ją projektował.',
      desc_2:
        'Siedzibą firmy jest Poznański Park Naukowo Technologiczny - miejsce gdzie nauka spotyka się z biznesem. Dzięki temu nasi pracownicy mają stały dostęp do najnowszych rozwiązań technologicznych a ich wiedza jest stale pogłębiana dzięki współpracy z naukowcami. Regularne szkolenia pomagają również naszym handlowcom. Nasz zespół obsługi klienta, jest przeszkolony w zakresie finansów i prawa podatkowego.',
      desc_3:
        'Skontaktuj się z nami, a za darmo przygotujemy wstępny projekt wraz z wyceną, pomożemy w uzyskaniu dotacji lub ulgi podatkowej a nawet przedstawimy symulację zwrotu z inwestycji. Do usłyszenia!',
    },
    benefitsSection: {
      title: 'Twoje korzyści z fotowoltaiki',
      benefits: {
        benefit_1: {
          id: 1,
          title: 'Zyskaj niezależność od cen prądu',
          description:
            'Ceny energii elektrycznej nie spadają a w ostatnich latach, podwyżki stają się coraz wyższe. Dzięki prywatnej elektrowni słonecznej, podwyżki cen prądu przestaną cię dotyczyć.',
        },
        benefit_2: {
          id: 2,
          title: 'Obniż rachunki za prąd nawet o 95%',
          description:
            'Dobrze dobrana instalacja fotowoltaiczna w twoim gospodarstwie domowym, zaspokoi 100% jego zapotrzebowania. W tej sytuacji, na  rachunku za prąd, pozostaną tylko stałe opłaty abonamentowe. Pozwól ekspertom Voltspot zaproponować instalację dopasowaną do twoich potrzeb.',
        },
        benefit_3: {
          id: 3,
          title: 'Skorzystaj z atrakcyjnych form finansowania',
          description:
            'Ze względu na to jak pożyteczne są elektrownie fotowoltaiczne, podlegają one szczególnej opiece. Jedną z jej form są dotacje na ich budowę. Inną, atrakcyjne kredyty umożliwiające inwestycję. Skontaktuj się z Voltspot a nasi konsultanci dopasują źródło finansowania do twoich indywidualnych potrzeb.',
        },
        benefit_4: {
          id: 4,
          title: 'Pozostaw swoim dzieciom czystą planetę',
          description:
            'Mając na uwadze zmiany klimatu z które obecnie obserwujemy, jest niezwykle ważne aby decyzje które podejmujemy dziś nie pogarszały sytuacji którą nasze dzieci zastaną jutro. Instalacje fotowoltaiczne bezpośrednio przyczyniają się do redukcji emisji CO2, która jest główną przyczyną zmian klimatycznych. Weź udział w zielonej transformacji i dołóż swoja cegiełkę w korzystajac z czystej energii słonecznej.',
        },
        benefit_5: {
          id: 5,
          title: 'Zyskaj wyższy zwrot inwestycji niż na lokacie',
          description:
            'Instalacja PV jest również doskonałą lokatą kapitału. Środki zainwestowane w fotowoltaikę procentują jako oszczędności na rachunkach za energię elektryczną. Zwrot kosztów może nastąpić już po pięciu latach od inwestycji. Od tego momentu elektrownia będzie generowała czysty zysk!',
        },
      },
    },
    realizationsSection: {
      stepsTitle: {
        title: 'Jak to robimy? W pięciu krokach!',
        subTitle: 'Nasze działania przy instalacji',
      },
      realizationsTitle: {
        title: 'Voltspot w skrócie',
        subTitle: 'Nasze ostatnie realizacje',
        picture_1: {
          title: '3,75kWp',
          subTitle: 'Inowrocław',
        },
        picture_2: {
          title: '6,37kWp',
          subTitle: 'Nowe budki',
        },
        picture_3: {
          title: '3,96kWp',
          subTitle: 'Poznań',
        },
      },
      button: 'Zobacz wszyskie realizacje',

      slides: {
        slide_1: {
          id: 1,
          title: '1. Darmowy audyt',
          description:
            'Spotkanie z nami nic nie kosztuje a możesz wiele zyskać! Nasz pracownik wstępnie oceni możliwości montażowe, przeanalizuje profil zużycia i zapozna się z twoimi potrzebami co do instalacji.',
        },
        slide_2: {
          id: 2,
          title: '2. Ocena opłacalności',
          description:
            'Na podstawie informacji zebranych podczas spotkania, nasz pracownik przygotuje wstępny projekt instalacji. Na jego podstawie obliczy roczną produkcję energii. Pozwoli ci to podjąć świadomą decyzję, jakiej instalacji potrzebujesz i kiedy inwestycja się zwróci.',
        },
        slide_3: {
          id: 3,
          title: '3. Montaż instalacji',
          description:
            'Po podjęciu decyzji i podpisaniu umowy, wspólnie uzgadniamy dogodny termin montażu. W zależności od rozmiaru instalacji, cały montaż zajmie od jednego do kilku dni roboczych.',
        },
        slide_4: {
          id: 4,
          title: '4. Formalności',
          description:
            'Niemal wszystkie formalności załatwi za ciebie Voltspot. Zgłosimy dla ciebie instalację i przygotujemy całą niezbędną dokumentację. Tobie pozostanie tylko korespondencyjne podpisanie umowy z dostawcą energii.',
        },
        slide_5: {
          id: 5,
          title: '5. Wniosek o dotację',
          description:
            'Po zakończonej instalacji Voltspot przygotuje i wyśle w twoim imieniu wnioski o dotacje. Ze względu na ilości instytucji i form dotacji, nasi pracownicy wyręczają klientów w formalnościach związanych z wnioskowaniem o dotacje.',
        },
      },
    },
    contactSection: {
      title: 'Formularz kontaktowy',
      subTitle: 'Uzyskaj ofertę',
    },
    forms: {
      inputs: {
        name: 'Imię i nazwisko',
        phone: 'Numer telefonu',
        email: 'Adres email',
        zipCode: 'Kod - pocztowy',
        city: 'Miejscowość',
        message: 'Jak możemy Ci pomóc?',
        checkbox:
          'Wypełnienie formularza oznacza, że podane w nim dane osobowe będą przetwarzane w celu przesłania oferty oraz kontaktu w jej sprawie. Zasady przetwarzania i informacje o administratorze znajdziesz w ',
        privacyPolicy: 'polityce prywatności',
        privacyPolicyURL: '/polityka-prywatnosci',
        offerBtn: 'Uzyskaj ofertę',
        sendBtn: 'Wyślij',
      },
      succesMessage: 'Wiadomość wysłana, wkrótce się z Tobą skontaktujemy!',
      errorMessage: 'Błąd wysyłania formularza, spróbuj ponownie!',
      errors: {
        required: 'Pole wymagane',
        email: 'Niepoprawny adres e-mail',
        phone: 'Niepoprawny numer telefonu',
        zipCode: 'Niepoprawny kod pocztowy.',
      },
    },
    footerSection: {
      divider: 'lub',
      title: 'Skontaktuj się z nami bezpośrednio!',
      adressData: {
        desc: 'Profesjonalne instalacje fotowoltaiczne',
        phone: '+48 783 603 704',
        mail: 'biuro@voltspot.pl',
      },
      copyrights: 'Copyright 2020. Wszystkie prawa zastrzeżone.',
    },
    contactSubpage: {
      seoTitle: 'Kontakt',
      heroTitle: 'Poznajmy się lepiej!',
      heroSubTitle: 'Kontakt',
      formTitle: 'Wyślij zapytanie',
      mapTitle: 'Jesteśmy tutaj',
      mapAdressLine_1: 'Rubież 46 bud.C4/89',
      mapAdressLine_2: '61-612 Poznań',
      dividerTitle: 'Dowiedz się o nas więcej',
    },
    realizationsSubpage: {
      seoTitle: 'Realizacje',
      title: 'Z czego jesteśmy dumni?',
      subTitle: 'Nasze realizacje',
      realizationsSectionTitle: 'Domy jednorodzinne',
      realizations: {
        item_1: {
          id: 0,
          city: 'Kikół',
          value: '19,5kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '52 moduły SUNTECH STP375S-B60/WNH',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 375Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik FOX ESS T20',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_2: {
          id: 1,
          city: 'Kostrzyn',
          value: '25,08kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '66 modułów JA Solar JAM60S20-380/MR',
            },
            param_2: {
              id: 2,
              value: 'Falownik SMA Sunny Tripower 25000TL-30',
            },
            param_3: {
              id: 3,
              value: 'Automatyczny wyłącznik PPOŻ',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_3: {
          id: 2,
          city: 'Koło',
          value: '6,46kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '17 modułów JA Solar JAM60S20-380/MR',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 380Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik Sofar Solar 6.6KTL-X',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_4: {
          id: 3,
          city: 'Inowrocław',
          value: '',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: 'Moc instalacji I – 8,25 kWp',
            },
            param_2: {
              id: 2,
              value: '22 modułów LONGi LR4-60HPH-375M',
            },
            param_3: {
              id: 3,
              value: 'Moc pojedynczego modułu: 375Wp',
            },
            param_4: {
              id: 4,
              value: 'Falownik SOFAR SOLAR 8.8 KTL-X',
            },
            param_5: {
              id: 5,
              value: 'Moc instalacji II – 6,38 kWp',
            },
            param_6: {
              id: 6,
              value: '17 modułów SUNTECH STP375S-B60/WNH',
            },
            param_7: {
              id: 7,
              value: 'Moc pojedynczego modułu: 375Wp',
            },
            param_8: {
              id: 8,
              value: 'Falownik SOLPLANET ASW TLC6000',
            },
          },
        },
        item_5: {
          id: 4,
          city: 'Broniewo',
          value: '4,5 kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '12 modułów LONGi Solar LR4-60-HPH-375M',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 375Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: Solplanet ASW TLC 4000',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_6: {
          id: 5,
          city: 'Inowrocław',
          value: '5,25kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '14 modułów SUNTECH STP375S-B60/WNH',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 375Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik SOLPLANET ASW TLC5000',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_7: {
          id: 6,
          city: 'Poznań',
          value: '9,88kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '26 modułów JaSolar JAM60S20-380/MR-BF',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 380Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: SolarEdge SE7K',
            },
            param_4: {
              id: 4,
              value: 'Optymalizatory SolarEdge P401-5R M4M RM',
            },
            param_5: {
              id: 5,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_6: {
              id: 6,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_7: {
              id: 7,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_8: {
          id: 7,
          city: 'Bogusławice',
          value: '9,88kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '26 modułów JaSolar JAM60S20-380/MR-BF',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 380Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: SOFAR SOLAR 11KTL-X',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_9: {
          id: 8,
          city: 'Swarzędz',
          value: '9,92 kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '31 modułów JA Solar JAM60S03-320/PR 320W mono Perc',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 375Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik SOFAR SOLAR 11KTL-X',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_10: {
          id: 9,
          city: 'Koło',
          value: '5,63kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '14 modułów SUNTECH STP375S-B60/WNH',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 375Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik SOLPLANET ASW TLC5000',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_11: {
          id: 10,
          city: 'Strzelce Krajeńskie',
          value: '5,7kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '15 modułów JaSolar JAM60S20-380/MR-BF',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 380Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: SOFAR SOLAR 5.5 KTL-X',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_12: {
          id: 11,
          city: 'Lusowo',
          value: '4,53kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '12 modułów LONGi Solar LR4-60-HPH-375M',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 370Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: SOFAR SOLAR 4.4 KTL-X',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_13: {
          id: 12,
          city: 'Sławęcin',
          value: '5,7kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '15 modułów JaSolar JAM60S20-380/MR-BF',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 380Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: GROWATT 5000 TL3-S',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_14: {
          id: 13,
          city: 'Koło',
          value: '5,25kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '16 modułów JaSolar JAM60S10-345/MR',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 345Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: SOFAR SOLAR 5.5 KTL-X',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_15: {
          id: 14,
          city: 'Strzelce Krajeńskie',
          value: '9,88kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '26 modułów JA Solar JAM60S20-380/MR',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 380Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik Sofar Solar 8.8KTL-X',
            },
            param_4: {
              id: 4,
              value: 'Automatyczny wyłącznik PPOŻ',
            },
            param_5: {
              id: 5,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_6: {
              id: 6,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_7: {
              id: 7,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_16: {
          id: 15,
          city: 'Inowrocław',
          value: '4,88kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '13 modułów LONGi Solar LR4-60-HPH-375M',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 375Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: SOFAR SOLAR 5.5 KTL-X',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_17: {
          id: 16,
          city: 'Koło',
          value: '7,5kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '20 modułów LONGi Solar LR4-60-HPH-375M',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 375Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: SOFAR SOLAR 6.6 KTL-X',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_18: {
          id: 17,
          city: 'Szczytniki',
          value: '7,56kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '21 modułów LONGi LR4-60HPB-360M',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 360Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: FRONIUS SYMO 7,0-3-M',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_19: {
          id: 18,
          city: 'Inowrocław',
          value: '6,38kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '17 modułów LONGi Solar LR4-60-HPH-375M',
            },
            param_2: {
              id: 2,
              value: 'Moc pojedynczego modułu: 375Wp',
            },
            param_3: {
              id: 3,
              value: 'Falownik: SOFAR SOLAR 6.6 KTL-X',
            },
            param_4: {
              id: 4,
              value: 'Skrzynka z zabezpieczeniami AC',
            },
            param_5: {
              id: 5,
              value: 'Osobna skrzynka z zabezpieczeniami DC',
            },
            param_6: {
              id: 6,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_20: {
          id: 19,
          city: 'Poznań',
          value: '3,96kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '12 modułów BAUER BS-6MHB5-EL 330Wp Full Black',
            },
            param_2: {
              id: 2,
              value: 'Falownik Growatt 4000TL3-S',
            },
            param_3: {
              id: 3,
              value: 'Czarne szyny montażowe dla jeszcze lepszego efektu wizualnego!',
            },
            param_4: {
              id: 4,
              value: 'Zabezpieczenia AC/DC',
            },
            param_5: {
              id: 5,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_21: {
          id: 20,
          city: 'Nowe Budki',
          value: '6,37kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '17 modułów LONGi Solar LR4-60HPH 375Wp z czarną ramą',
            },
            param_2: {
              id: 2,
              value: 'Falownik Huawei SUN2000-5KTL-M0',
            },
            param_3: {
              id: 3,
              value: 'Czarne szyny montażowe dla jeszcze lepszego efektu wizualnego!',
            },
            param_4: {
              id: 4,
              value: 'Zabezpieczenia AC/DC',
            },
            param_5: {
              id: 5,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_22: {
          id: 21,
          city: 'Zielona Góra',
          value: '4,5kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '12 modułów LONGi Solar LR4-60HPH 375Wp z czarną ramą',
            },
            param_2: {
              id: 2,
              value: 'Falownik Growatt 4000TL3-S',
            },
            param_3: {
              id: 3,
              value: 'Zabezpieczenia AC/DC Growatt WireBox',
            },
            param_4: {
              id: 4,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
        item_23: {
          id: 22,
          city: 'Inowrocław',
          value: '3,75kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '10 modułów LONGi Solar LR4-60HPH 375Wp z czarną ramą',
            },
            param_2: {
              id: 2,
              value: 'Falownik Growatt 3000TL3-S',
            },
            param_3: {
              id: 3,
              value: 'Zabezpieczenia AC/DC Growatt WireBox',
            },
            param_4: {
              id: 4,
              value: 'Przewody solarne o przekroju 6mm',
            },
            param_5: {
              id: 5,
              value: '15-letnia gwarancja PREMIUM',
            },
          },
        },
        item_24: {
          id: 23,
          city: 'Tupadły',
          value: '6,29kWp',
          parametersTitle: 'Parametry instalacji',
          parameters: {
            param_1: {
              id: 1,
              value: '17 modułów JA Solar JAM60S20-370/MR z czarną ramą',
            },
            param_2: {
              id: 2,
              value: 'Falownik Growatt 6000TL3-S',
            },
            param_3: {
              id: 3,
              value: 'Zabezpieczenia AC/DC Growatt WireBox',
            },
            param_4: {
              id: 4,
              value: 'Przewody solarne o przekroju 6mm',
            },
          },
        },
      },
    },
    blogSubpage: {
      seoTitle: 'Blog',
      title: 'Blog',
      subTitle: 'Dowiedz się więcej!',
      button: 'Czytaj więcej',
    },
    ourCompanySubpage: {
      seoTitle: 'Nasza firma',
      title: 'Fachowy zespół',
      subTitle: 'Nasza firma',
      sectionTitle_1: 'Nasza Firma',
      sectionTitle_2: 'Fachowy Zespół',
      text_1:
        'W <strong>Voltspot</strong> wiemy dokładnie, że zapotrzebowanie na energię elektryczną jest coraz większe, co dotyczy zarówno gospodarstw domowych, jak i przedsiębiorstw. Rosnące ceny prądu to długofalowy trend, który wedle wiarygodnych szacunków nie zakończy się szybko. Raport Instytutu Energetyki Odnawialnej nie pozostawia wątpliwości, że ceny będą systematycznie rosnąć aż do 2040 roku!',
      text_2:
        'Wiemy o tym doskonale, dlatego chcemy Ci zaoferować kompleksowe rozwiązania wykorzystujące Odnawialne Źródła Energii (OZE), zwłaszcza w zakresie fotowoltaiki. Ta darmowa energia słoneczna <strong>zmniejszy Twoje rachunki za prąd nawet o 95%</strong>, przyczyniając się jednocześnie do zmniejszenia emisji CO2 do atmosfery. Zaoszczędzisz bardzo dużo pieniędzy i zatroszczysz się o dobrostan środowiska naturalnego.',
      text_3:
        'Voltspot tworzą <strong>wykwalifikowani eksperci</strong>, dysponujący ogromną wiedzą i doświadczeniem w dziedzinie odnawialnych źródeł energii. Niezależnie od miejsca montażu i wielkości instalacji, zapewnione jest indywidualne podejście, fachowe doradztwo, szybkie terminy i uczciwe ceny.',
      text_4:
        'Oferujemy pomoc na <strong>każdym etapie procesu inwestycji</strong> w fotowoltaikę. Najpierw przeprowadzamy darmowy audyt, podczas którego ocenimy możliwości założenia instalacji. Sprawdzimy roczne zużycie energii i na tej podstawie dobierzemy odpowiednią instalację, obliczając, w jakim czasie zwróci się ta inwestycja. Po tych przygotowaniach dopasujemy odpowiednie podzespoły i przystąpimy do montażu. Po podpisaniu umowy, ruszamy do pracy. Zależnie od wielkości instalacji montaż zajmie od jednego do kilku dni roboczych.',
      text_5:
        '<strong>Wykonamy też za Ciebie dodatkowe czynności</strong> - wyślemy pismo do energetyki z prośbą o przyłączenie mikroinstalacji do sieci energetycznej. Pomożemy też z wnioskami o dostępne dotacje.',
    },
    privacyPolicySubpage: {
      seoTitle: 'Polityka prywatności',
      subTitle: 'Polityka prywatności',
      sectionLabel: 'Polityka prywatności serwisu www.voltspot.pl',
      paragraphs: {
        paragraph_1: {
          id: 1,
          value:
            'Informacje ogólne <br/> Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników w związku z korzystaniem przez nich z serwisu www, w szczególności przez użycie formularza celem kontaktu z w/w Użytkownikiem.',
        },
        paragraph_2: {
          id: 2,
          value:
            'Administratorem danych osobowych zawartych w serwisie jest Voltspot spółka z ograniczoną odpowiedzialnością z siedzibą w Gowarzewie NIP 7773364104 REGON 386823320.',
        },
        paragraph_3: {
          id: 3,
          value:
            'W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach prawa wspólnotowego.',
        },
        paragraph_4: {
          id: 4,
          value:
            'Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają Administratora do przetwarzania danych osobowych na podstawie przepisów prawa lub w celu realizacji zawartej pomiędzy stronami umowy.',
        },
        paragraph_5: {
          id: 5,
          value:
            'Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniach w następujący sposób: poprzez dobrowolnie wprowadzone w formularzach informacje.',
        },
        paragraph_6: {
          id: 6,
          value: 'Serwis zbiera informacje dobrowolnie podane przez użytkownika.',
        },
        paragraph_7: {
          id: 7,
          value:
            'Dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza np. w celu skontaktowania się i przedstawienia oferty.',
        },
        paragraph_8: {
          id: 8,
          value:
            'Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.',
        },
        paragraph_9: {
          id: 9,
          value:
            'Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej, która je tam umieściła. Osoba ta ma również prawo do modyfikacji i zaprzestania przetwarzania swoich danych w dowolnym momencie.',
        },
        paragraph_10: {
          id: 10,
          value:
            'Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności serwisu, na które może wpłynąć rozwój technologii internetowej, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój naszego serwisu internetowego. O wszelkich zmianach będziemy informować w sposób widoczny i zrozumiały.',
        },
        paragraph_11: {
          id: 11,
          value:
            'W Serwisie mogą pojawiać się linki do innych stron internetowych. Takie strony internetowe działają niezależnie od Serwisu i nie są w żaden sposób nadzorowane przez serwis www.voltspot.pl. Strony te mogą posiadać własne polityki dotyczące prywatności oraz regulaminy, z którymi zalecamy się zapoznać. W razie wątpliwości do któregokolwiek z zapisów niniejszej polityki prywatności jesteśmy do dyspozycji - nasze dane znaleźć można w zakładce - KONTAKT.',
        },
      },
    },
  },
};
