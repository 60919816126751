import styled, { createGlobalStyle } from "styled-components";
import Panels from "../../images/contactPageBg.png";

export const GlobalStyle = createGlobalStyle`
html,
body {
    padding: 0;
    margin: 0;
    font-size: 62.5%;
    font-family: 'Titillium Web', sans-serif;
    background: ${({ theme }) => theme.colors.grey};
    overflow-x: hidden;
}

button {
  &:focus {
    outline: 0;
  }
}

video {
  &:focus {
    outline: 0;
  }
}

*, *::before, *::after {
    box-sizing: border-box;
}
`;

export const StyledBlogContent = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.yellow};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: ${({ theme }) => theme.fontSizes.large};
  }

  p,
  li {
    color: ${({ theme }) => theme.colors.black_2};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }

  h4 {
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
  @media only screen and (max-width: 768px) {
    padding: 0 50px;
    h2 {
      font-size: ${({ theme }) => theme.fontSizes.medium};
    }

    p,
    li {
      font-size: ${({ theme }) => theme.fontSizes.small};
    }
    h4 {
      font-size: ${({ theme }) => theme.fontSizes.medium};
    }
  }
  @media only screen and (max-width: 425px) {
    padding: 0 20px;
  }
`;

export const StyledImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 1300px;
  @media only screen and (max-width: 1024px) {
    margin: 20px 0;
  }
`;

export const StyledArticleContent = styled.div`
  padding: 0 149px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 0 50px;
  }
  @media only screen and (max-width: 425px) {
    padding: 0 20px;
  }
`;

export const StyledArticleTitle = styled.h3`
  color: ${({ theme }) => theme.colors.lighterBlack};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  margin: 0;

  @media only screen and (max-width: 1048px) {
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
  }

  @media only screen and (max-width: 425px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

export const StyledArticleSection = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 60px;
  margin-bottom: 100px;
  box-shadow: 0 8px 24px 0 rgb(0 0 0 / 4%);
  margin-top: 10px;
  @media only screen and (max-width: 768px) {
    padding: 60px 10px;
  }
`;
